// TODO cover this entrypoint with e2e tests

const elements: Element[] = []
let openCookieConsentCallback = () => {}
let mountCookieDeclarationCallback = (element: Element): any =>
  elements.push(element)

// boot from single webpack chunk by lazy loading the rest of the app
;(async () => {
  const { startCookieConsent, mountCookieDeclaration } = await import(
    /* webpackChunkName: "JottaConsentApp" */ '../ConsentApp'
  )
  openCookieConsentCallback = startCookieConsent()
  mountCookieDeclarationCallback = element => mountCookieDeclaration(element)
  elements.forEach(mountCookieDeclarationCallback)
})()

// noinspection JSUnusedGlobalSymbols
export const openCookieConsent = () => openCookieConsentCallback()

// noinspection JSUnusedGlobalSymbols
export const startCookieConsent = () => {
  // noop until craft starts using this
  console.log('starting cookie consent')
}

// noinspection JSUnusedGlobalSymbols
export const mountCookieDeclaration = (element: any) =>
  mountCookieDeclarationCallback(element)
